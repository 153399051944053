import React from 'react';

import { flowRight } from 'lodash';
import AppLoaded from '../../../common/components/app-loaded';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import TagCloud from '../tag-cloud';
import styles from './app-root.scss';

type OwnProps = {};

const AppRoot: React.FC<OwnProps> = () => {
  return (
    <ResponsiveListener dataHook="tag-cloud-root" className={styles.baseStyles}>
      <>
        <TagCloud />
        <AppLoaded />
      </>
    </ResponsiveListener>
  );
};
export default flowRight(withReduxStore)(AppRoot) as React.FC<OwnProps>;
