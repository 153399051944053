import { AppState } from '../../common/types';

export const getTagCloudWidgetData = (state: AppState) => {
  const tagsAsArray = state.tagCloudWidget.tags
    ? Object.values(state.tagCloudWidget.tags)
    : [];

  return {
    tags: tagsAsArray,
    translations: {
      emptyState: state.tagCloudWidget.emptyState ?? '',
      tags: state.tagCloudWidget.tags ?? {},
    },
  };
};
