import React from 'react';
import { useSelector } from '../../../common/components/runtime-context';
import Tags from '../../../common/components/tags';
import { getTagCloudWidgetData } from '../../store/tag-cloud.selectors';

const TagCloud: React.FC = () => {
  const tagCloudWidget = useSelector(getTagCloudWidgetData);

  return (
    <Tags
      tags={tagCloudWidget.tags}
      translations={tagCloudWidget.translations}
    />
  );
};

export default TagCloud;
